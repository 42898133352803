import { DownloadBookModal } from '../../Modals';
import React from "react";
import quoteImage from "../../../img/quote.png";

const Quote = ({ quote }) => {
  return (
    <div className="quote-container">
      <img className="quote-img" src={quoteImage} alt="quote image" />
      <div className="quote-text-container" dangerouslySetInnerHTML={{ __html: quote }}></div>
    </div>
  );
};


const QuotesSection = ({ content: { frontmatter, html } }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <div>
      <h3>{frontmatter.title}</h3>
      <div className="quotes-container">
        {html.split("\n").map((quote, index) => (
          <Quote key={index} quote={quote} />
        ))}
      </div>
      <div className="pattern-banner-container">
        <button onClick={() => setModalOpen(true)} className="quotes-button">download free sample</button>
      </div>
      <DownloadBookModal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default QuotesSection;
