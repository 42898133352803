import React from "react";
import amazonKindleLogo from "../../../img/amazonKindle.png";
import amazonLogo from "../../../img/amazon.png";
import barnesNobleLogo from "../../../img/barnes&noble.png";

const links = [
  {
    href:
      "https://www.amazon.com/Joint-Force-Leadership-Fighter-Success/dp/1642934836",
    target: "_blank",
    image: amazonLogo,
    alt: "amazon logo",
    className: "amazon",
  },
  {
    href:
      "https://www.barnesandnoble.com/w/joint-force-leadership-jim-boots-demarest/1134271554",
    target: "_blank",
    image: barnesNobleLogo,
    alt: "barnes and noble logo",
    className: "barnes-and-noble",
  },
  {
    href:
      "https://www.amazon.com/Joint-Force-Leadership-Fighter-Success-ebook/dp/B0859KPGPZ/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=",
    target: "_blank",
    image: amazonKindleLogo,
    alt: "amazon kindle logo",
    className: "amazon-kindle",
  },
];

const BuyNowButton = ({ href, target }) => (
  <a className="buy-now-button" href={href} target={target}>
    BUY NOW
  </a>
);

const StoreLogos = ({ backgroundColor, showButtons }) => {
  return (
    <div className="logos-container" style={{ backgroundColor }}>
      <ul className="stores">
        {links.map(({ href, target, image, alt, className }, index) => {
          return (
            <li key={index} className={className}>
              <a
                href={href}
                target={target}
                style={{ backgroundImage: `url(${image})` }}
              >
                {alt}
              </a>
            </li>
          );
        })}
      </ul>
      {showButtons && (
        <ul className="stores btns">
          {links.map(({ href, target, className }, index) => {
            return (
              <li key={index} className={className}>
                <BuyNowButton href={href} target={target} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default StoreLogos;
