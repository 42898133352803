import React from "react";
import {BuyNowModal, DownloadBookModal} from "../Modals";
import { Link } from "gatsby"

import logo from "../../img/logo.png";

const Header = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false)

  return (
    <div className="header-container">
      <div className="header-content-container">
        <Link to="/">
        <div className="logo-container">
          <img className="logo-img" src={logo} alt="Joint Force Leadership" />
          <div className="logo-text-container">
            <p className="logo-text-title">JOINT FORCE</p>
            <p className="logo-text-subtitle">LEADERSHIP</p>
          </div>
        </div>
          </Link>
        <div className="links-container">
          <a className="link" href="#authors">
            AUTHORS
          </a>
          <a className="link" href="#" onClick={() => setDownloadModalOpen(true)}>
            FREE BOOK SAMPLE
          </a>
          <BuyNowModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
          />
          <button className="link btn" onClick={() => setModalOpen(true)}>
            BUY NOW
          </button>
        </div>
      </div>
      <div className="header-bottom-line" />
      <DownloadBookModal
          isOpen={downloadModalOpen}
          onRequestClose={() => setDownloadModalOpen(false)}
      />
    </div>
  );
};

export default Header;
