import React from "react";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import logo from "../../../img/logo.png";

const Author = ({ name, desc, image, index }) => {
  const imgClasses = index === 0 ? "first-element author-img" : "author-img";
  return (
    <div className="author-container">
      <div className="author-img-container">
        <Img className={imgClasses} fluid={image.childImageSharp.fluid} />
      </div>
      <h4 className="author-name">{name}</h4>
      <div
        className="author-bio"
        dangerouslySetInnerHTML={{ __html: desc }}
      ></div>
    </div>
  );
};

const Separator = () => (
  <div className="author-separator-container">
    <img src={logo} className="author-separator"></img>
  </div>
);

const AuthorsSection = ({ content, authors }) => {
  const {
    frontmatter: { title, heroImage },
  } = content;
  return (
    <section id="authors">
      <h3>{title}</h3>
      <BackgroundImage
        fluid={heroImage.childImageSharp.fluid}
        className="authors-section-container"
      >
        <div className="authors-container">
          {authors.map((author, index) => (
            <React.Fragment key={index}>
              <Author
                index={index}
                name={author.frontmatter.title}
                desc={author.html}
                image={author.frontmatter.image}
              />
              {index !== authors.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </div>
      </BackgroundImage>
    </section>
  );
};

export default AuthorsSection;
