import React from "react";

const IntroductionSection = (props) => {
  const {
    content: { frontmatter, html },
  } = props;

  return (
    <div className="section-container">
      <div className="section-content-container">
        <h2>{frontmatter.title}</h2>
        <div className="section-description" dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
};

export default IntroductionSection;
