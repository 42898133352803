import React from "react";
import StoreLogos from "../StoresLogos/StoresLogos";
import Img from "gatsby-image";
import { BuyNowModal } from "../../Modals";

const Footer = ({ content: { frontmatter } }) => {
  const { image, title } = frontmatter;
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <div className="footer-container">
      <div className="footer-content-container">
        <Img className="footer-img" fluid={image.childImageSharp.fluid} />
        <div className="title-container">
          <p>{title}</p>
          <BuyNowModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
          />
          <button className="footer-button" onClick={() => setModalOpen(true)}>
            buy now
          </button>
        </div>
      </div>
      <StoreLogos />
    </div>
  );
};

export default Footer;
