import React from "react";
import { graphql } from "gatsby";
import AuthorsSection from "../components/getTheBook/AuthorsSection/AuthorsSection";
import HeroBanner from "../components/getTheBook/HeroBanner/HeroBanner";
import IntroductionSection from "../components/getTheBook/IntroductionSection/IntroductionSection";
import NewLayout from "../components/NewLayout";
import PowerfulGuide from "../components/getTheBook/PowerfulGuide/PowerfulGuide";
import WhoIsFor from "../components/getTheBook/WhoIsFor/WhoIsFor";
import QuotesSection from "../components/getTheBook/QuotesSection/QuotesSection";
import StoreLogos from "../components/getTheBook/StoresLogos/StoresLogos";
import Footer from "../components/getTheBook/Footer/Footer";
import "../components/all.scss";

const GetTheBookPage = ({ data }) => {
  const {
    getTheBook,
    introduction,
    guide,
    quotes,
    authorsList,
    footer,
    whoIsFor,
    authorSection,
  } = data;

  const authors = authorsList.edges.map((edge) => edge.node);

  return (
    <NewLayout>
      <HeroBanner content={getTheBook} />
      <StoreLogos />
      <IntroductionSection content={introduction} />
      <PowerfulGuide content={guide} />
      <WhoIsFor content={whoIsFor} />
      <QuotesSection content={quotes} />
      <AuthorsSection authors={authors} content={authorSection} />
      <Footer content={footer} />
    </NewLayout>
  );
};

export default GetTheBookPage;

export const query = graphql`
  query GetTheBookQuery {
    getTheBook: markdownRemark(frontmatter: { path: { eq: "/get-the-book" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
    introduction: markdownRemark(
      frontmatter: { path: { eq: "/get-the-book-introduction" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    guide: markdownRemark(
      frontmatter: { path: { eq: "/get-the-book-guide" } }
    ) {
      frontmatter {
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
    whoIsFor: markdownRemark(
      frontmatter: { path: { eq: "/get-the-book-who-is-for" } }
    ) {
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    quotes: markdownRemark(
      frontmatter: { path: { eq: "/get-the-book-quotes" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    authorSection: markdownRemark(
      frontmatter: { path: { eq: "/get-the-book-meet-the-author" } }
    ) {
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    authorsList: allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "/authors/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    footer: markdownRemark(frontmatter: { path: { eq: "/footer" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
