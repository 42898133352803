import React from "react";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { BuyNowModal } from "../../Modals";

const Desktop = ({
  content: {
    frontmatter: { title, image, heroImage },
    html,
  },
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <BackgroundImage
      fluid={heroImage.childImageSharp.fluid}
      className="banner-container"
    >
      <div className="content-container">
        <div className="book-cover-container">
          <Img className="book-cover-img" fluid={image.childImageSharp.fluid} />
        </div>
        <div className="banner-content">
          <h1 className="banner-content-title">{title}</h1>
          <div
            className="banner-content-description"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
          <BuyNowModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
          />
          <button onClick={() => setModalOpen(true)} className="btn">
            buy now
          </button>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Desktop;
