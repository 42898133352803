import React from "react";
import BackgroundImage from "gatsby-background-image";

const ReaderNiche = ({ title, subtitle, img, index }) => {
  const containerClassName = `reader-container ${
    index % 2 !== 0 ? "reversed" : ""
  }`;
  const imgClassName = `reader-img ${img} `;

  return (
    <div className={containerClassName}>
      <div className={imgClassName}></div>
      <div className="reader-info">
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

const WhoIsFor = ({
  content: {
    frontmatter: { title, heroImage },
  },
}) => {
  return (
    <div className="who-is-for-container">
      <div className="title-container">
        <h2>{title}</h2>
      </div>
      <BackgroundImage
        fluid={heroImage.childImageSharp.fluid}
        className="items-container"
      >
        <div className="items">
          {content.map((item, index) => {
            return <ReaderNiche key={index} {...item} index={index} />;
          })}
        </div>
      </BackgroundImage>
    </div>
  );
};

export default WhoIsFor;

const content = [
  {
    title: "executives and managers",
    img: "executive",
    subtitle: "Looking to gain a strategic advantage and lead by example",
  },
  {
    title: "entrepeneurs",
    img: "entrepeneurs",
    subtitle: "Focusing on growth and ready to gain a hidden advantage",
  },
  {
    title: "military front-line",
    img: "militaryFrontLines",
    subtitle: "Seeking to rise to victory and take the lead",
  },
  {
    title: "athletes",
    img: "athlete",
    subtitle:
      "Devoted to the highest level of success through leadership development",
  },
  {
    title: "military enthusiasts",
    img: "militaryEnthusiasts",
    subtitle: "Purposeful in their approach to developing success in life",
  },
  {
    title: "parents",
    img: "parents",
    subtitle:
      "Intent on providing positive direction and sustained personal growth to their families",
  },
  {
    title: "everyone",
    img: "everyone",
    subtitle:
      "Seeking to improve their relationships in life and business, and ready to finally get ahead",
  },
];
