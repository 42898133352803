import React from "react";
import BackgroundImage from "gatsby-background-image";
import StoreLogos from "../StoresLogos/StoresLogos";
import { BuyNowModal } from "../../Modals";

const PowerfulGuide = (props) => {
  const {
    content: {
      frontmatter: { title, subtitle, heroImage },
      html,
    },
  } = props;

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <div>
      <div className="guide-title">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <BackgroundImage
        fluid={heroImage.childImageSharp.fluid}
        className="guide-content-container"
      >
        <div className="guide-content">
          <div
            className="guide-content-text"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <BuyNowModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
          />
          <button onClick={() => setModalOpen(true)}>Buy Now</button>
          <StoreLogos backgroundColor="transparent" />
        </div>
      </BackgroundImage>
    </div>
  );
};

export default PowerfulGuide;
