import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import StoreLogos from "./getTheBook/StoresLogos/StoresLogos";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby-link";

Modal.setAppElement("#___gatsby");

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90vw"
  },
};

export const BuyNowModal = (props) => {
  return (
    <Modal {...props} style={customStyles}>
      <div className="modal-container">
        <div className="close-icon-container">
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={props.onRequestClose}
            className="close-icon"
          />
        </div>
        <h2>BUY JOINT FORCE LEADERSHIP FROM YOUR FAVORITE RETAILER!</h2>
        <StoreLogos showButtons />
      </div>
    </Modal>
  );
};

export const DownloadBookModal = (props) => {
  const [form, setForm] = useState({
    u:"3",
    f: "3",
    s: null,
    c: "0",
    m: "0",
    act: "sub",
    v: "2",
  })
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    window._load_script = function (url, callback) {
      var head = document.querySelector('head'), script = document.createElement('script'), r = false;
      script.type = 'text/javascript';
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == 'complete')) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };

    window._show_thank_you = (...params) => {
    
    }
  }
  const handleChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (typeof window !== "undefined") {
        window._load_script('https://jointforceleadership.activehosted.com/proc.php?' + encode(form) + '&jsonp=true')
        window.open('/files/book.pdf', '_blank');
        navigate("/thank-you-guide");
      }

    } catch (err) {
      alert(err);
    }
  }

  const style = {
    overlay: customStyles.overlay,
    content: {
      width: 500,
      ...customStyles.content
    }
  }
  return (
    <Modal {...props} style={style}>
      <form onSubmit={handleSubmit} id="_form_3_" name="leadershipguide">
        <input type="hidden" name="u" value="3" />
        <input type="hidden" name="f" value="3" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <input type="hidden" name="u" value="3" />
        <div className="field">
          <label className="label">First Name:</label>
          <input className="input" type="text" required="required" name="fullname" onChange={handleChange} />
        </div>
        <div className="field">
          <label className="label">Email:</label>
          <input className="input" type="email" name="email" required="required" onChange={handleChange}/>
        </div>
        <div className="field submit">
          <button type="submit" className="quotes-button is-fullwidth">Submit</button>
        </div>
      </form>
    </Modal>
  );
};